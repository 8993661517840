import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <h1> Hi, J4ck </ h1>
          <br />
          <p>
            Now that we have your attention... 
            <br />
            Email: info at hij4ck dot com
          </ p>
      </header>
    </div>
  );
}

export default App;
